<template>
  <div class="Userhl_baoming Userhl_rightbox">
    <ul class="Userhl_baomingrwtype">
      <li v-for="(item, index) in rwtype" :key="index" @click="sx(item)">
        {{ item }}
      </li>
    </ul>
    <ul class="Userhl_baomingrwzt">
      <li v-for="(item, index) in rwzt" :key="index" @click="sx(item)">
        {{ item }}
      </li>
    </ul>
    <div class="Userhl_home_b_sbox">
      <el-table :data="tableData" border style="width: 100%" stripe>
        <el-table-column prop="undertake" label="任务类型" style="">
        </el-table-column>
        <el-table-column prop="title" label="任务名称" style="">
        </el-table-column>
        <el-table-column prop="money" label="任务金额"></el-table-column>
        <el-table-column prop="taskpace" label="任务进展"></el-table-column>
        <el-table-column prop="zt" label="任务状态"></el-table-column>
        <el-table-column
          prop="checked"
          label="我的状态"
          v-if="idtype == 'myTask'"
        ></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button @click="ck(scope.$index)" type="text">查看</el-button>
            <span v-if="weituoshuzu[scope.$index]">
              <br />
              <el-button @click="weituo(5, scope.$index)" type="text"
                >同意委托</el-button
              >
              <br />
              <el-button
                @click="
                  weituptype = true;
                  xiangmuindex = scope.$index;
                "
                type="text"
                >拒绝委托</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <el-dialog title="项目委托提示" :visible.sync="weituptype" width="30%">
        <span>您确定拒绝此项目的委托邀请吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="weituo(7, xiangmuindex)">确定</el-button>
          <el-button type="primary" @click="weituptype = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
    <el-button class="linkbtn" plain @click="linkxm">前往项目大厅</el-button>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".Userhl_baomingrwtype li", function () {
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    border: "none",
  });
  $(this).siblings().eq(0).css({
    color: "#999",
  });
});
$(document).on("click", ".Userhl_baomingrwzt li", function () {
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    border: "none",
  });
  $(this).siblings().eq(0).css({
    color: "#999",
  });
});
export default {
  components: {},
  data() {
    return {
      xiangmuindex: 0,
      weituptype: false,
      activeName: "first",
      tableData: [],
      pagesize: 10,
      total: 0,
      rwtype: ["任务类型", "全部类型", "竞标任务", "直接委托"],
      rwzt: ["任务状态", "全部状态", "进行中", "已完成"],
      rwtypetext: "",
      rwzttext: "",
      idtype: "",
      userinfo: {},
      weituoshuzu: [],
    };
  },
  methods: {
    linkxm() {
      this.$router.push({ path: "/xiangmu" });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    currentchange(index) {
      console.log(index);
    },
    axiosqq(text) {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/" + that.idtype + "",
          method: "post",
          data: {
            undertake: that.rwtypetext,
            taskpace: that.rwzttext,
            page: "1",
            limit: that.pagesize,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.total = res.data.result.count;
            let resall = res.data.result.list;
            if(text=='进行中'){
              resall = resall.filter((val) => val.taskpace != 6);
            }
            // let ress = [{ a: 1 }, { a: 1 }, { a: 2 }, { a: 3 }];
            // let result = ress.filter((val) => val.a != 1);
            // console.log(result)
            for (var i = 0; i < resall.length; i++) {
              if (resall[i].undertake == 1) {
                resall[i].undertake = "委托项目";
              } else {
                resall[i].undertake = "竞标项目";
              }
              //////////
              if (resall[i].taskpace == 0) {
                resall[i].taskpace = "报名";
                resall[i].zt = "进行中";
              } else if (resall[i].taskpace == 1) {
                resall[i].taskpace = "调研分析与决策";
                resall[i].zt = "进行中";
              } else if (resall[i].taskpace == 2) {
                resall[i].taskpace = "初步设计与提案";
                resall[i].zt = "进行中";
              } else if (resall[i].taskpace == 3) {
                resall[i].taskpace = "方案深化与确立";
                resall[i].zt = "进行中";
              } else if (resall[i].taskpace == 4) {
                resall[i].taskpace = "技术实现与扩初";
                resall[i].zt = "进行中";
              } else if (resall[i].taskpace == 5) {
                resall[i].taskpace = "最终成果与交付";
                resall[i].zt = "进行中";
              } else if (resall[i].taskpace == 6) {
                resall[i].taskpace = "项目结束";
                resall[i].zt = "已完成";
              }
              if (resall[i].tk_state == "2") {
                resall[i].zt = "待审核";
              } else if (resall[i].tk_state == "-1") {
                resall[i].zt = "已下线";
              }

              ////////////////
              if (resall[i].checked == 1) {
                resall[i].checked = "同意";
                this.weituoshuzu.push(false);
              } else if (resall[i].checked == 2) {
                resall[i].checked = "淘汰";
                this.weituoshuzu.push(false);
              } else if (resall[i].checked == 3) {
                resall[i].checked = "设计师取消报名";
                this.weituoshuzu.push(false);
              } else if (resall[i].checked == 4) {
                resall[i].checked = "入围";
                this.weituoshuzu.push(false);
              } else if (resall[i].checked == 5) {
                resall[i].checked = "中标";
                this.weituoshuzu.push(false);
              } else if (resall[i].checked == 6) {
                resall[i].checked = "项目委托待同意";
                this.weituoshuzu.push(true);
              } else if (resall[i].checked == 7) {
                resall[i].checked = "已拒绝";
                this.weituoshuzu.push(false);
              }
            }
            that.tableData = resall;
          } else {
            console.log("请求失败");
          }
        });
    },
    sx(item) {
      var that = this;
      that.tableData = [];
      if (item == "竞标任务") {
        that.rwtypetext = "0";
        this.axiosqq();
      } else if (item == "直接委托") {
        that.rwtypetext = "1";
        this.axiosqq();
      } else if (item == "进行中") {
        that.rwzttext = "";
        this.axiosqq("进行中");
      } else if (item == "已完成") {
        that.rwzttext = "6";
        this.axiosqq();
      } else if (item == "全部状态") {
        that.rwzttext = "";
        this.axiosqq();
      } else if (item == "全部类型") {
        that.rwtypetext = "";
        this.axiosqq();
      }
    },
    ck(index) {
      var id = this.tableData[index].id;
      this.$router.push({ path: "/xiangmu_C", query: { data: id } });
    },
    weituo(checked, index) {
      var that = this;
      var task_id = this.tableData[index].id;
      var sign_id = this.tableData[index].sign_id;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getTaskRefuse",
          method: "post",
          data: {
            id: sign_id,
            task_id,
            checked,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.$message(res.data.messages);
            location.reload();
          } else {
            console.log("请求失败");
          }
        });
    },
  },
  created() {
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;
    // userinfo.groupid = 2;
    if (userinfo.groupid == "1") {
      that.idtype = "myTask";
    } else if (userinfo.groupid == "2") {
      that.idtype = "firmTask";
    }
    this.axiosqq();
  },
};
</script>
